<template>
<div>
    <b-alert :variant="currentVariant" :show="dismissCountDown" @dismissed="dismissCountDown=0" class="text-left"
        @dismiss-count-down="alertCountDownChanged" fade v-html="alertDescription"></b-alert>
</div>
</template>

<script>
export default {
    name: 'dismissAlert',
    props: {
        dismissSecs: {
            type: Number,
            required: false,
            default: 6
        }
    },    
    data() {
        return {
            currentVariant: this.variant,
            dismissCountDown: 0,
            alertDescription: ''
        }
    },
    methods: {
        alertCountDownChanged(dismissCountDown) {
            this.dismissCountDown = dismissCountDown;
        },
        alertShow(alertDescription) {
            this.currentVariant = 'success';
            this.alertDescription = alertDescription;
            this.dismissCountDown = this.dismissSecs;
        },
        alertShowDanger(alertDescription) {
            this.currentVariant = 'danger';
            this.alertDescription = alertDescription;
            this.dismissCountDown = this.dismissSecs;
        },
        hideAlert() {
            this.alertDescription = '';
            this.dismissCountDown = 0;
        }
    }
}
</script>