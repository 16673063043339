<template>
    <div class="form-group row" :class="{error: error}" >
        <label :for="name" v-if="label" class="col-form-label col-sm-3">{{ label }}</label>
        <b-form-textarea v-model="valueProxy" :rows="rows" max-rows="6" placeholder=" " :name="name" @input="updateValue()" 
            v-on:blur="updateBlur" class="col-sm-9" ></b-form-textarea>

        <div class="form-text text-danger col-sm-9 offset-sm-3" v-if="error">{{ error }}</div>
    </div>
</template>

<script>
export default {
    name: 'inputTextarea',
    props: {
        rows: {
            type: Number,
            default: 3
        },
        name: {
            type: String,
            required: true,
        },
        value: {
            required: true,
        },
        label: {
            type: String,
            required: false,
            default: null
        },
        error: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            valueProxy: this.value,
        }
    },
    watch: {
        value: function (val) {
            this.valueProxy = val;
        },
    },
    methods: {
        updateValue: function () {
            this.$emit('change', true);
            this.$emit('input', this.valueProxy);
        },
        updateBlur: function () {
            this.$emit('blur', true);
        }
    }
    
}
</script>