import axios from "axios";
import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);
axios.defaults.baseURL = process.env.VUE_APP_BACKEND_HTTP;
axios.defaults.headers.common['Accept'] = 'application/json';

import moduleAuth from '@/store/modules/auth';
import moduleCustomer from '@/store/modules/customer';
import moduleOrder from '@/store/modules/order';
import moduleDriver from '@/store/modules/driver';
import moduleAdmin from '@/store/modules/admin';

export default new Vuex.Store({
    state: {
        breads: [],
        buns: [],
        pastries: [],
    },
    mutations: {
        setBreads( state, breads ) {  state.breads = breads;  },
        setBuns( state, buns ) {  state.buns = buns;  },
        setPastries( state, pastries ) {  state.pastries = pastries;  },
    },
    getters: {
        getBreads: state => state.breads,
        getBuns: state => state.buns,
        getPastries: state => state.pastries,
    },
    actions: {
        async loadProducts(context) {
            await axios.get('/products' ).then(response => {
                context.commit('setBreads', response.data.breads);
                context.commit('setBuns', response.data.buns);
                context.commit('setPastries', response.data.pastries);
            })
            .catch( error => {
                console.log(error);
            });
        },
    },
    modules: {
        auth: moduleAuth,
        customer: moduleCustomer,
        order: moduleOrder,
        admin: moduleAdmin,
        driver: moduleDriver,
    }
});
