import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

Vue.use(VueRouter)

const ifNotAuth = (to, from, next) => {
  if (!store.getters['auth/loggedIn']) {
    next()
    return
  }
  next({ name: 'CustomerAccount' })
}

const ifAuth = (to, from, next) => {
  if (store.getters['auth/loggedIn']) {
    next()
    return
  }
  next({ name: 'Home' })
}

const ifAuthCustomer = (to, from, next) => {
  if (store.getters['auth/isCustomer']) {
    next()
    return
  }
  next({ name: 'Home' })
}

const ifAuthAdmin = (to, from, next) => {
  if (store.getters['auth/isAdmin']) {
    next()
    return
  }
  next({ name: 'Home' })
}

const ifAuthDriver = (to, from, next) => {
  if (store.getters['auth/isDriver']) {
    next()
    return
  }
  next({ name: 'Home' })
}

const routes = [
  //HOME
  {
    path: '',
    name: 'Home',
    component: () => import('../views/Home.vue'),
    meta: {
      title: 'Chleb Pod Dom - Codziennie świeży',
      metaTags: [
        {
          name: 'keywords',
          content: 'dostawy pieczywa, chleb pod dom, dostawa pieczywa, zamów pieczywo, dostawa chleba do domu, chleb z dowozem, chleb z dostawą, pieczywo z dostawą, Dostawa pieczywa do domu, piekarnia z dowozem',
        },
        {
          name: 'description',
          content: 'Dostawy świeżego pieczywa codziennie rano do Twojego domu.',
        },
        {
          property: 'og:title',
          content: 'Chleb Pod Dom - Codziennie świeży',
        },
        {
          property: 'og:description',
          content: 'Dostawy świeżego pieczywa codziennie rano do Twojego domu.',
        },
        {
          property: 'og:image',
          content: 'https://www.chlebpoddom.pl/img/page_preview.png',
        },
        {
          property: 'og:image',
          content: 'https://www.chlebpoddom.pl',
        },
        {
          name: 'facebook-domain-verification',
          content: 'y1c5wusrc4v1rzujlh8y6ejekewswx',
        },
      ],
    },
  },

  //AUTH
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/auth/Login.vue'),
  }, //  beforeEnter: ifNotAuth },
  {
    path: '/logout',
    name: 'Logout',
    component: () => import('../views/auth/Logout.vue'),
    beforeEnter: ifAuth,
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('../views/auth/Register.vue'),
    beforeEnter: ifNotAuth,
  },
  {
    path: '/register-thanks',
    name: 'RegisterThankYou',
    component: () => import('../views/auth/RegisterThanks.vue'),
    beforeEnter: ifNotAuth,
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: () => import('../views/auth/ForgotPassword.vue'),
    beforeEnter: ifNotAuth,
  },
  {
    path: '/reset-password/:token/:email',
    name: 'resetPassword',
    component: () => import('../views/auth/ResetPassword.vue'),
    beforeEnter: ifNotAuth,
  },
  {
    path: '/verify-email/:token',
    name: 'verifyEmail',
    component: () => import('../views/auth/VerifyEmail.vue'),
  },

  //TEXT
  { path: '/help', name: 'Help', component: () => import('../views/Help.vue') },
  {
    path: '/delivery',
    name: 'Delivery',
    component: () => import('../views/Delivery.vue'),
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import('../views/Contact.vue'),
  },
  {
    path: '/terms',
    name: 'Terms',
    component: () => import('../views/Terms.vue'),
  },
  {
    path: '/privete-policy',
    name: 'PrivatePolicy',
    component: () => import('../views/PrivatePolicy.vue'),
  },
  {
    path: '/payment_thanks',
    name: 'PaymentThanks',
    component: () => import('../views/PaymentThanks.vue'),
  },

  //CUSTOMER
  {
    path: '/customer',
    component: () =>
      import('../components/layout/container/customerContainer.vue'),
    beforeEnter: ifAuthCustomer,
    children: [
      {
        path: '',
        name: 'CustomerAccount',
        component: () => import('../views/customer/Account.vue'),
      },
      {
        path: 'change-password',
        name: 'CustomerChangePassword',
        component: () => import('../views/auth/ChangePassword.vue'),
      },
      {
        path: 'change-data',
        name: 'CustomerChangeData',
        component: () => import('../views/customer/ChangeData.vue'),
      },
    ],
  },

  {
    path: '/products',
    name: 'Products',
    component: () => import('../views/customer/Products.vue'),
  },
  {
    path: '/order/add',
    name: 'OrderAdd',
    component: () => import('../views/customer/Order.vue'),
  },
  {
    path: '/order/payment/:orderId',
    name: 'OrderPayment',
    component: () => import('../views/customer/OrderPayment.vue'),
  },
  {
    path: '/order/info/:orderId',
    name: 'OrderPreview',
    component: () => import('../views/customer/OrderPreview.vue'),
  },
  {
    path: '/order/list',
    name: 'OrderList',
    component: () => import('../views/customer/OrderList.vue'),
  },

  //ADMIN
  {
    path: '/admin',
    component: () =>
      import('../components/layout/container/adminContainer.vue'),
    beforeEnter: ifAuthAdmin,
    children: [
      {
        path: '',
        name: 'AdminAccount',
        component: () => import('../views/admin/Account.vue'),
      },
      {
        path: 'change-password',
        name: 'AdminChangePassword',
        component: () => import('../views/auth/ChangePassword.vue'),
      },
      {
        path: 'change-data',
        name: 'AdminChangeData',
        component: () => import('../views/admin/ChangeData.vue'),
      },
      {
        path: 'calendar',
        name: 'AdminCalendar',
        component: () => import('../views/admin/Calendar.vue'),
      },
      {
        path: 'cities',
        name: 'AdminCities',
        component: () => import('../views/admin/Cities.vue'),
      },
      {
        path: 'cities-asked',
        name: 'AdminCitiesAsked',
        component: () => import('../views/admin/CitiesAsked.vue'),
      },
      {
        path: 'costs',
        name: 'AdminCosts',
        component: () => import('../views/admin/Costs.vue'),
      },
      {
        path: 'deliveries',
        name: 'AdminDeliveries',
        component: () => import('../views/admin/Deliveries.vue'),
      },
      {
        path: 'delivery-report/:deliveryId',
        name: 'AdminDeliveryReport',
        component: () => import('../views/admin/DeliveryReport.vue'),
      },
      {
        path: 'delivery-list/:day',
        name: 'adminDeliveriesList',
        component: () => import('../views/admin/DeliveriesList.vue'),
      },
      {
        path: 'delivery-list-products/:day',
        name: 'adminDeliveriesListProducts',
        component: () => import('../views/admin/DeliveriesListProducts.vue'),
      },
      {
        path: 'holidays',
        name: 'AdminHolidays',
        component: () => import('../views/admin/Holidays.vue'),
      },
      {
        path: 'payments',
        name: 'AdminPayments',
        component: () => import('../views/admin/Payments.vue'),
      },
      {
        path: 'orders',
        name: 'AdminOrders',
        component: () => import('../views/admin/Orders.vue'),
      },
      {
        path: 'order/:orderId',
        name: 'AdminOrder',
        component: () => import('../views/admin/Order.vue'),
      },
      {
        path: 'orderdelete',
        name: 'AdminOrderDelete',
        component: () => import('../views/admin/OrderDelete.vue'),
      },
      {
        path: 'baskets',
        name: 'AdminBaskets',
        component: () => import('../views/admin/Baskets.vue'),
      },
      {
        path: 'products',
        name: 'AdminProducts',
        component: () => import('../views/admin/Products.vue'),
      },
      {
        path: 'routes',
        name: 'AdminRoutes',
        component: () => import('../views/admin/Routes.vue'),
      },
      {
        path: 'addresses',
        name: 'AdminAddresses',
        component: () => import('../views/admin/Addresses.vue'),
      },
      {
        path: 'users',
        name: 'AdminUsers',
        component: () => import('../views/admin/users/Users.vue'),
      },
      {
        path: 'users/preview/:userId',
        name: 'AdminUserPreview',
        component: () => import('../views/admin/users/UserPreview.vue'),
      },
      {
        path: 'users/add',
        name: 'AdminUserAdd',
        component: () => import('../views/admin/users/UserAdd.vue'),
      },
      {
        path: 'users/edit/:userId',
        name: 'AdminUserEdit',
        component: () => import('../views/admin/users/UserEdit.vue'),
      },
    ],
  },

  //DRIVER
  {
    path: '/admin',
    component: () =>
      import('../components/layout/container/adminContainer.vue'),
    beforeEnter: ifAuthDriver,
    children: [
      {
        path: '',
        name: 'DriverAccount',
        component: () => import('../views/driver/Account.vue'),
      },
      {
        path: 'change-password',
        name: 'DriverChangePassword',
        component: () => import('../views/auth/ChangePassword.vue'),
      },
      {
        path: 'change-data',
        name: 'DriverChangeData',
        component: () => import('../views/driver/ChangeData.vue'),
      },
      {
        path: 'calendar',
        name: 'DriverCalendar',
        component: () => import('../views/driver/Calendar.vue'),
      },
    ],
  },

  //ERROR
  { path: '*', name: '404', component: () => import('../views/error404.vue') },
]

const router = new VueRouter({
  mode: 'history',
  routes,
});
// ...

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
    // This goes through the matched routes from last to first, finding the closest route with a title.
    // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
    // `/nested`'s will be chosen.
    const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);
  
    // Find the nearest route element with meta tags.
    const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
  
    const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
  
    // If a route with a title was found, set the document (page) title to that value.
    if(nearestWithTitle) {
      document.title = nearestWithTitle.meta.title;
    } else if(previousNearestWithMeta) {
      document.title = previousNearestWithMeta.meta.title;
    }
  
    // Remove any stale meta tags from the document using the key attribute we set below.
    Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));
  
    // Skip rendering meta tags if there are none.
    if(!nearestWithMeta) return next();
  
    // Turn the meta tag definitions into actual elements in the head.
    nearestWithMeta.meta.metaTags.map(tagDef => {
      const tag = document.createElement('meta');
  
      Object.keys(tagDef).forEach(key => {
        tag.setAttribute(key, tagDef[key]);
      });
  
      // We use this to track which meta tags we create so we don't interfere with other ones.
      tag.setAttribute('data-vue-router-controlled', '');
  
      return tag;
    })
    // Add the meta tags to the document head.
    .forEach(tag => document.head.appendChild(tag));
  
    next();
  });
  
  // ...

export default router
