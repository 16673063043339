<template>
    <div class="form-group row" :class="{error: error}" >
        <label :for="name" v-if="label" class="col-form-label col-sm-3">{{ label }}</label>
        <label :name="name"  class="col-form-label col-sm-9" >{{ format(value) }}</label>

        <div class="form-text text-danger col-sm-9 offset-sm-3" v-if="error">{{ error }}</div>
    </div>
</template>

<script>
export default {
    name: 'inputLabel',
    props: {
        name: {
            type: String,
            required: true,
        },
        value: {
            required: true,
        },
        label: {
            type: String,
            required: false,
            default: null
        },
        error: {
            type: String,
            default: null
        },
        type: {
            type: String,
            default: null
        }
    },
    methods: {
        format(value){
            return this.type ? this.formatters[this.type](value) : value
        }
    }
    
}
</script>