<template>
    <div>
        <header class="header">
            <div class="container">
                <div class="row">
                    <div class="col-md-4">
                        <!-- Logo -->
                        <router-link :to="{name: 'Home'}" class="logo">
                            <img src="@/assets/logo-poziome.svg" alt="Logo">
                        </router-link>
                        <!-- End Logo -->
                    </div>
                    <div class="col-md-8 text-right text-md-center">
                        <router-link :to="{name: 'Products'}" class="btn m-md-2">Produkty</router-link>
                        <router-link :to="{name: 'Delivery'}" class="btn m-md-2">Dostawa</router-link>
                        <router-link :to="{name: 'Contact'}" class="btn m-md-2">Kontakt</router-link>
                        <router-link :to="{name: 'OrderAdd'}" class="btn m-md-2 mr-1 btn-order">zamawiam on-line</router-link>
                        <div class="phone-order-info d-md-inline-block d-xs-none d-sm-none d-none">
                            lub telefonicznie
                            <br />
                            <a href="tel:+48333332885">(+48) 333 332 885</a>
                        </div>
                        <router-link :to="{name: 'OrderAdd'}" class="btn m-md-2 btn-basket">{{ $store.state.order.schedule.productTemplates.length}} <font-awesome-icon :icon="['fas', 'shopping-cart']" /></router-link>

                        <b-dropdown id="dropdown-1" class="m-md-2 menu-panel" right  variant="default">
                            <template #button-content>
                                <span v-if="!isLoggedIn"><font-awesome-icon :icon="['fas', 'user']" /></span>
                                <span v-else><font-awesome-icon :icon="['fas', 'user']" /></span>
                            </template>
                            <b-dropdown-item v-if="!isLoggedIn" :to="{name: 'Register'}">Rejestracja</b-dropdown-item>
                            <b-dropdown-item v-if="isLoggedIn"  :to="accountLink()">Moje konto</b-dropdown-item>
                            <b-dropdown-divider v-if="isLoggedIn"></b-dropdown-divider>
                            <b-dropdown-item v-if="!isLoggedIn" :to="{name: 'Login'}" >Zaloguj</b-dropdown-item>
                            <b-dropdown-item v-if="isLoggedIn" :to="{name: 'Logout'}">Wyloguj</b-dropdown-item>
                        </b-dropdown>
                    </div>
                </div>
            </div>
        </header>
        <div class="container">
            <div class="over-header-logo bgDark">
                <router-link :to="{name: 'Home'}">
                    <img src="@/assets/logo.svg" alt="Logo">
                </router-link>
            </div>
        </div>
    </div>

</template>

<script>
import { mapGetters} from "vuex";
import { USER_typeAdmin, USER_typeDriver, USER_typeCustomer  } from "@/constants/constants.js";

export default {
    name: 'layoutHeader',
    computed: {
        ...mapGetters({isLoggedIn: 'auth/loggedIn', me : 'auth/me' }),
    },
    mounted(){
        this.loadBasketInHeader();
    },
    methods: {
        accountLink() {
            switch (this.$store.getters['auth/getUserType'] ) {
                case USER_typeAdmin: { return {name: 'AdminAccount'}; }
                case USER_typeDriver: { return {name: 'DriverAccount'}; }
                case USER_typeCustomer: { return {name: 'CustomerAccount'}; }
                default: { return {name: 'Login'}; }
            }
        },
        loadBasketInHeader() {
            this.$store.dispatch('order/loadBasket')
                .then( () => {}); 
        }
    }
}
</script>