<template>
    <div class="form-group row" :class="{error: error}">
        <label :for="name" v-if="label" class="col-form-label col-sm-3">{{ label }}</label>
        <div class="input-group col-sm-9 no-gutters px-0">
            <input :type="showInput" class="form-control" :value="value" v-on:input="updateValue($event.target.value)" 
                :name="name" placeholder=" " :autocomplete="autocomplete" v-on:blur="updateBlur">
            <div class="input-group-append">
                <span class="input-group-text cursorPointer" @click="showHideClick">
                    <font-awesome-icon :icon="showLabel" />
                </span>
            </div>
        </div>

        <div class="form-text text-danger col-sm-9 offset-sm-3" v-if="error">{{ error }}</div>
    </div>
</template>
<i class="fas fa-eye-slash"></i>
<script>
export default {
    name: 'inputPassword',
    props: {
        name: {
            type: String,
            required: true,
        },
        value: {
            required: true,
        },
        label: {
            type: String,
            required: false,
            default: null
        },
        newPassword: {
            type: Boolean,
            default: false
        },
        error: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            password: '',
            showInput: 'password',
            showLabel: ['fas', 'eye'],
            autocomplete: this.newPassword ? 'new-password' : '',
        }
    },
    methods: {
        showHideClick() {
            if ( this.showInput == 'password' ) {
                this.showInput = 'input';
                this.showLabel = ['fas', 'eye-slash'];
            } else {
                this.showInput = 'password';
                this.showLabel = ['fas', 'eye'];
            }
        },
        updateValue: function (value) {
            this.$emit('change', true);
            this.$emit('input', value)
        },
        updateBlur: function () {
            this.$emit('blur', true);
        }
    }
    
}
</script>