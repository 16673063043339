const ACCOUNT_statusPaid = 1;
const ACCOUNT_statusUnpaid = 2;

const COST_typeDelivery = 1;
const COST_TypeBox = 2;

const DELIVERY_statusNew = 1;
const DELIVERY_statusDelivered = 2;
const DELIVERY_statusUndelivered = 3;

const PRODUCT_groupBread = 1;
const PRODUCT_groupBun = 2;
const PRODUCT_groupPastry = 3;

const SCHEDULE_typeSubscription = 1;
const SCHEDULE_typeOneTimePamyent = 2;

const SCHEDULE_statusNew = 0;
const SCHEDULE_statusToPay = 1;
const SCHEDULE_statusPaid = 2;
const SCHEDULE_statusEnded = 3;
const SCHEDULE_statusCanceled = 4;

const DELIVERY_DATE_statusToPay = 0;
const DELIVERY_DATE_statusPaid = 4;
const DELIVERY_DATE_statusDelivered = 1;
const DELIVERY_DATE_statusDeliveryFailed = 2;
const DELIVERY_DATE_statusCanceled = 3;

const USER_typeAdmin = 1;
const USER_typeDriver = 10;
const USER_typeCustomer = 20;

//TODO: zabezpieczyć płacenie po czasie
const orderToNextDayMaxHour = 19;

export { 
    ACCOUNT_statusPaid, ACCOUNT_statusUnpaid,
    COST_typeDelivery, COST_TypeBox,
    DELIVERY_statusNew, DELIVERY_statusDelivered, DELIVERY_statusUndelivered,
    PRODUCT_groupBread, PRODUCT_groupBun, PRODUCT_groupPastry,
    SCHEDULE_typeSubscription, SCHEDULE_typeOneTimePamyent, 
    SCHEDULE_statusNew, SCHEDULE_statusToPay, SCHEDULE_statusPaid, SCHEDULE_statusEnded, SCHEDULE_statusCanceled,
    DELIVERY_DATE_statusToPay, DELIVERY_DATE_statusPaid, DELIVERY_DATE_statusDelivered, DELIVERY_DATE_statusDeliveryFailed, DELIVERY_DATE_statusCanceled,
    USER_typeAdmin, USER_typeDriver, USER_typeCustomer,
    orderToNextDayMaxHour
};