<template>
    <div class="wrapper page-option-v1">
        <layoutHeader/>
        <slot />
        <layoutFooter  />
    </div>
</template>

<script>
import LayoutFooter from '@/components/layout/defaultPartial/Footer.vue';
import LayoutHeader from '@/components/layout/defaultPartial/Header.vue';

export default {
    name: 'layoutDefault',
    components: {
        LayoutFooter,
        LayoutHeader
    },
    data(){
        return {
            me: null,
        }
    },
    // methods: {
    //     logout() {
    //         proceedLogout(this.$apollo);
    //         this.logged = false;
    //     }
    // }
}
</script>