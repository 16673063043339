<template>
    <div class="form-group row" :class="{error: error}" >
        <label :for="name" v-if="label" class="col-form-label col-sm-3" v-bind:class="{ empty: (!valueProxy) }" >{{ label }}</label>
        <b-form-datepicker class="col-sm-9" v-model="valueProxy" 
            :locale="locale"
            :label-prev-decade="labels[locale].labelPrevDecade" 
            :label-prev-year="labels[locale].labelPrevYear" 
            :label-prev-month="labels[locale].labelPrevMonth" 
            :label-current-month="labels[locale].labelCurrentMonth" 
            :label-next-month="labels[locale].labelNextMonth" 
            :label-next-decade="labels[locale].labelNextDecade" 
            :label-today="labels[locale].labelToday" 
            :label-selected="labels[locale].labelSelected" 
            :label-no-date-selected="labels[locale].labelNoDateSelected" 
            :label-calendar="labels[locale].labelCalendar" 
            :label-nav="labels[locale].labelNav" 
            :label-help="labels[locale].labelHelp" 
            
            size="sm" placeholder=" "
            start-weekday="1" 
            :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
            :max="max" :min="min" :date-disabled-fn="dateDisabled" 
            
            @input="updateValue()" v-on:blur="updateBlur"
            ></b-form-datepicker>

        <div class="form-text text-danger col-sm-9 offset-sm-3" v-if="error">{{ error }}</div>
    </div>
</template>

<script>
// reset-button
//:date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
//:date-format-options="{ dateTimeFormat: 'short' }"
// v-bind="labels[locale] || {}"
import moment from 'moment';
export default {
    name: 'inputDatePicker',
    props: {
        name: {
            type: String,
            required: true,
        },
        value: {
            type: String
        },
        label: {
            type: String,
            required: false,
            default: null
        },
        min: {
            type: Date
        },
        max: {
            type: Date
        },
        error: {
            type: String,
            default: null
        },
        disabledDates: {
            type: Array,
            default: function () { return [] }
        }
    },
    data() {
        return {
            valueProxy: this.value,
            locale: 'pl',
            labels: {
                pl: {
                    labelPrevDecade: 'Poprzednia dekada',
                    labelPrevYear: 'Poprzedni rok',
                    labelPrevMonth: 'Poprzedni miesiąc',
                    labelCurrentMonth: 'Obecny miesiąc',
                    labelNextMonth: 'Następny miesiąc',
                    labelNextYear: 'Następny rok',
                    labelNextDecade: 'Następna dekada',
                    labelToday: 'Dziś',
                    labelSelected: 'Wybrana data',
                    labelNoDateSelected: 'Nie wybrano daty',
                    labelCalendar: 'Kalendarz',
                    labelNav: 'Nawigacja',
                    labelHelp: '', //'Użyj strzałek do zmiany daty'
                },
            }
        }
    },
    methods: {
        updateValue: function () {
            this.$emit('input', moment(this.valueProxy).format('YYYY-MM-DD') );
            this.$emit('change', true);
        },
        updateBlur: function () {
            this.$emit('blur', true);
        },

        dateDisabled(ymd, date) {
            // Disable weekends (Sunday = `0`, Saturday = `6`) and
            
            const weekday = date.getDay();
            return weekday === 0 || this.disabledDates.includes( moment(date).format('YYYY-MM-DD') );
        }
    }

}
</script>