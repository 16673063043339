<template>
    <button :type="type" :class="buttonClass" :disabled="isDisabled" v-on:click="$emit('click')">
        <font-awesome-icon v-if="isLoading" :icon="['fas', 'spinner']" class="fa-spin mr-2 buttonFaSpin" />
        {{ label }}
    </button>
</template>
<script>
export default {
    name: 'buttonLoader',
    props: {
        label:  {
            type: String,
            required: true
        },
        isLoading: {
            type: Boolean,
            required: true
        },
        variant: {
            type: String,
            required: false,
            default: 'primary'
        },
        type: {
            type: String,
            required: false,
            default: 'button'
        },
        canClick: {
            type: Boolean,
            required: false,
            default: true
        }
    },
    computed: {
        buttonClass: function () {
            return 'btn btn-' + this.variant;
        },
        isDisabled: function () {
            return !this.canClick || this.isLoading;
        }
    },
    data() {
        return {
        }
    },
    methods: {
    }
    
}
</script>