<template>
    <footer class="footer py-4">
        <div class="container">
            <div class="row">
                <div class="col-12 col-md-3">
                    <router-link :to="{name:'Home'}"><img src="@/assets/nowe/logo-poziome.svg" id="logo-footer" class="footer-logo" alt="logo" /></router-link>
                </div>
                <div class="col-6 col-md-3">
                    <ul class="list-unstyled">
                        <li>
                            <router-link :to="{name: 'Products'}" >produkty</router-link>
                        </li>
                        <li>
                            <router-link :to="{name: 'Delivery'}" >dostawa</router-link>
                        </li>
                        <li>
                            <router-link :to="{name: 'Contact'}" >kontakt</router-link>
                        </li>
                    </ul>
                </div>
                <div class="col-6 col-md-3">
                    <ul class="list-unstyled">
                        <li>
                            <router-link :to="{name: 'Terms'}" >regulamin</router-link>
                        </li>
                        <li>
                            <router-link :to="{name: 'PrivatePolicy'}" >polityka prywatności</router-link>
                        </li>
                        <li>
                            <router-link :to="{name: 'Help'}" >pomoc</router-link>
                        </li>
                    </ul>
                </div>
                <div class="col-12 col-md-3">
                    <router-link :to="{name: 'OrderAdd'}" class="btn mb-2 btn-order">zamawiam on-line</router-link>
                    <div class="phone-order-info">
                        lub zamawiam telefonicznie
                        <br />
                        <a href="tel:+48333332885">(+48) 333 332 885</a>
                    </div>
                </div>

            </div>

            <div class="row mt-5">
                <div class="col-12 col-md-3 socials">
                    <a href="https://www.facebook.com/ChlebPodDom" target="_blank" class="tooltips" data-toggle="tooltip" data-placement="top" title="" data-original-title="Facebook">
                        <font-awesome-icon :icon="['fab', 'facebook-square']" /> FACEBOOK
                    </a>
                </div>
                <div class="col-12 col-md-6">
                    <font-awesome-icon :icon="['fas', 'map-marker-alt']" class="mr-2" /> 
                    al. Armii Krajowej 220,
                    43-300 Bielsko-Biała
                    <br />
                    tel.: <a href="tel:+48333332885" class="mr-3">(+48) 333 332 885</a>
                    e-mail: <a href="mailto:zamawiam@chlebpoddom.pl">zamawiam@chlebpoddom.pl</a>
                </div>
                <div class="col-12 col-md-3">
                    <small>
                        2021 &copy; wszelkie prawa zastrzeżone.
                    </small>
                </div>
            </div>
        </div>
        
    </footer>
</template>

<script>
export default {
    name: 'layoutFooter',
}
</script>
