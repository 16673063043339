<template>
    <div class="form-group row" :class="{error: error}" >
        <label :for="name" v-if="label" class="col-form-label col-sm-3">{{ label }}</label>

        <b-form-select  class="form-control col-sm-9"  v-model="valueProxy" :options="options" 
            @input="updateValue()" placeholder=" " v-on:blur="updateBlur" ></b-form-select>

        <div class="form-text text-danger col-sm-9 offset-sm-3" v-if="error">{{ error }}</div>
    </div>
</template>

<script>
export default {
    name: 'inputSelect',
    props: {
        name: {
            type: String,
            required: true,
        },
        options: {
            required: true,
        },
        value: {
            required: true,
        },
        label: {
            type: String,
            required: false,
            default: null
        },
        error: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            valueProxy: this.value,
        }
    },
    methods: {
        updateValue: function () {
            this.$emit('change', true);
            this.$emit('input', this.valueProxy);
        },
        updateBlur: function () {
            this.$emit('blur', true);
        }
    }
    
}
</script>