const validationLanguage = {
    error: 'Błąd.',
    required: 'Wymagane.',
    float: 'Musi być liczbą.',
    integer: 'Musi zawierać tylko cyfry.',
    number: 'Musi zawierać tylko cyfry.',
    lessThan: 'Musi być mniejsze od {0}.',
    lessThanOrEqualTo: 'Musi być mniejsze lub równe {0}.',
    greaterThan: 'Musi być większe od {0}.',
    greaterThanOrEqualTo: 'Musi być większe lub równe {0}.',
    between: 'Musi być pomiędzy {0} a {1}.',
    size: 'Wymagana wielkość {0}.',
    length: 'Wymagana długość {0}.',
    minLength: 'Musi mieć co najmniej {0} znaków.',
    maxLength: 'Nie może mieć więcej niż {0} znaków.',
    lengthBetween: 'Dozwolona długość pomiędzy {0} a {1}.',
    in: 'Musi być {0}.',
    notIn: 'Nie może być {0}.',
    match: 'Nie są takie same.',
    regex: 'Błędny format.',
    digit: 'Musi być cyfrą.',
    email: 'Błąd adresu email.',
    url: 'Błędny url.',
    optionCombiner: function (options) {
        if (options.length > 2) {
            options = [options.slice(0, options.length - 1).join(', '), options[options.length - 1]];
        }
        return options.join(' lub ');
    }
};

export default validationLanguage;