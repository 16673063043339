<template>
    <div id="app">
        <layoutDefault>
            <router-view v-if="!isLoading" />
        </layoutDefault>
    </div>
</template>

<script>
import layoutDefault from '@/components/layout/Default.vue';

import { mapActions} from "vuex";

export default {
    name: "App",
    components: {
        layoutDefault
    },
    data() {
        return {
            isLoading: false
        }
    },
    methods: {
        ...mapActions({ loadProducts: 'loadProducts', loadMe: 'auth/loadMe', loadHolidays: 'order/loadHolidays' })
    },
    mounted(){
        this.loadMe().finally( () => {
            this.isLoading = false;
        });
        this.loadProducts();
        this.loadHolidays();
    }
};
</script>
