import axios from "axios";
axios.defaults.headers.common['Accept'] = 'application/json';


import { USER_typeAdmin, USER_typeDriver, USER_typeCustomer  } from "@/constants/constants.js";

const moduleAuth = {
    namespaced: true,
    state: {
        token: localStorage.getItem('access_token') || null,
        me: null
    },
    mutations: {
        setToken( state, token ) {  state.token = token;  },
        setMe( state, me ) {  state.me = me;  },
        logout( state ) {
            state.token = null;
            state.me = null;
            //TODO: wyczyścić wszystkie inne dane
        }
    },
    getters: {
        loggedIn: state => state.token !== null ,
        token: state => state.token,
        me: state => state.me,
        isAdmin: state => state.me ? state.me.type == USER_typeAdmin : false,
        isDriver: state => state.me ? state.me.type == USER_typeDriver : false,
        isCustomer: state => state.me ? state.me.type == USER_typeCustomer : false,
        getUserType: state => state.me ? state.me.type : null,
    },
    actions: {
        login(context, credentials) {
            return new Promise( (resolve, reject) =>{ 
                axios.post('/login', {
                    login: credentials.email,
                    password: credentials.password
                }).then(response => {
                    const token = response.data.access_token;
                    localStorage.setItem('access_token',token);
                    context.commit('setToken', token);
                    context.dispatch( 'loadMe' ).then( () => {
                        //console.log('loadMe');
                        resolve( true );
                    });
                    
                    
                }).catch( error => {
                    reject(error) 
                });
            });
        },

        logout( context ) {
            if ( context.getters.loggedIn ) {
                return new Promise( (resolve, reject) =>{ 
                    axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token;
                    axios.get('/logout').then( () => {
                        resolve();
                    }).catch( error => {
                        reject(error); 
                    }).finally( () => {
                        localStorage.removeItem('access_token');
                        context.commit('logout', null);
                        context.commit('setToken', null);
                        window.location = "/";
                    });
                });
            } 
        },
        loadMe( context ) {
            return new Promise( (resolve, reject) =>{ 
                if ( context.getters.loggedIn ) {
                    axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token;
                    axios.get('/me' ).then( response => {
                        context.commit('setMe', response.data);
                        resolve(true);
                    }).catch( () => {
                        localStorage.removeItem('access_token');
                        context.commit('setToken', null);
                        reject();
                    });
                }
            });
        },
        checkEmailIsFree( context, data ) {
            return new Promise( (resolve, reject) =>{ 
                axios.post('/isFreeEmail', {
                    email: data.email,
                    userId: data.id
                }).then( () => {
                    resolve(  );
                }).catch( error  => {
                    if ( error.response.data.errors && error.response.data.errors.email )
                        reject( 'Ten email jest już przypisany do istniejącego konta. Spróbuj się zalogować.' );
                    else
                        reject('Błąd sprawdzania email');
                });
            });
        },
        checkCitySupported( context, data ) {
            return new Promise( (resolve, reject) =>{ 
                axios.post('/availability', {
                    'availability': data.code
                }).then( (result) => {
                    if( result.data.status == 1 ) {
                        resolve( );
                    } else {
                        resolve( result.data.message )
                    }
                }).catch( error  => {
                    if ( error.response.status == 2 )
                        reject( 'Błąd, nie obsługiwana miejscowość.' );
                    else
                        reject('Błąd sprawdzania kodu');
                });
            });
        },
        register( context, data ) {
            return new Promise( (resolve, reject) =>{ 
                axios.post('/register', {
                    email: data.email,
                    password: data.password,
                    name: data.name,
                    phone: data.phone != '' ? data.phone : null,
                    surname: data.surname
                }).then(response => {
                    resolve( response );
                }).catch( error => {
                    reject(error.response.data);
                });
            });
        },
        verifyEmail( context, token ) {
            return new Promise( (resolve, reject) =>{ 
                axios.get('/verifyEmail/' + token ).then( response => {
                    resolve( response );
                }).catch( error => {
                    reject(error.response.data.message);
                });
            });
        },
        forgotPassword( context, data ) {
            return new Promise( (resolve, reject) =>{ 
                axios.post('/forgotPassword', {
                    email: data.email
                }).then( response => {
                    resolve( response );
                }).catch( error => {
                    reject(error.response.data.message);
                });
            });
        },
        updateForgottenPassword( context, data ) {
            return new Promise( (resolve, reject) =>{ 
                axios.post('/updateForgottenPassword', {
                    email: data.email,
                    token: data.token,
                    password: data.password
                }).then( response => {
                    resolve( response );
                }).catch( error => {
                    reject(error.response.data.message);
                });
            });
        },

        changePassword(context, data) {
            return new Promise( (resolve, reject) =>{ 
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token;
                axios.post('/changePassword', {
                    password: data.password,
                    oldPassword: data.oldPassword
                }).then(response => {
                    resolve( response );
                }).catch( error =>  {
                    reject(error.response.data.message) 
                });
            });
        },
        
    },
  }
  

export default moduleAuth;