import axios from "axios";
axios.defaults.headers.common['Accept'] = 'application/json';

const moduleCustomer = {
    namespaced: true,
    state: {
        addresses: null
    },
    mutations: {
        setAddresses( state, addresses ) {  state.addresses = addresses;  },
        changeAddress( state, {old_addres_id, new_address} ) {
            if ( new_address.id != old_addres_id ) {
                state.addresses = state.addresses.filter( item => item.id != old_addres_id );
                state.addresses.push( new_address );
            } else {
                const i = state.addresses.findIndex( item => item.id == new_address.id );
                state.addresses[i] = new_address;
            }
        },
        setDefaultAddress( state, addressId) {
            for (const address of state.addresses){
                address.default = address.id == addressId ? true : false;
            }
        },
        removeAddress( state, id ) {   state.addresses = state.addresses.filter( item => item.id != id );  },
    },
    getters: {
        addresses: state => state.addresses,
    },
    actions: {
        changeData(context, data, ) {
            return new Promise( (resolve, reject) =>{ 
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.rootGetters['auth/token'];
                axios.post('/customer/changeData', {
                    email: data.email,
                    name: data.name,
                    phone: data.phone,
                    surname: data.surname
                }).then(response => {
                    resolve( response.data.message );
                }).catch( error =>  {
                    reject( error.response.data.message) 
                });
            });
        },
        loadAddresses(context) {
            return new Promise( (resolve, reject) =>{
                // TODO -- potwierdzić po co był ten cache i czy mozna go usunać bo jego wprowadzenie robilo zamieszniae przy formualrzach edycji zadresu i ich anuowaniu?
                // const addresses = context.getters.addresses;
                // if ( addresses !== null) {
                //     resolve( addresses );
                // } else {
                    axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.rootGetters['auth/token'];
                    axios.get('/customer/addresses').then(response => {
                        context.commit('setAddresses', response.data);
                        resolve( response.data );
                    }).catch( error => {
                        reject(error) 
                    });
                // }
            });
        },
        saveAddress(context, address) {
            return new Promise( (resolve, reject) =>{ 
                
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.rootGetters['auth/token'];
                axios.post('/customer/saveAddress', address ).then(response => {
                    context.commit('changeAddress', { old_addres_id: address.id, new_address: response.data.address} );
                    resolve( response.data.address ); 
                }).catch( error => {
                    reject(error) 
                });
            });
        },
        setDefaultAddress(context, address) {
            return new Promise( (resolve, reject) =>{ 
                
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.rootGetters['auth/token'];
                axios.post('/customer/setDefaultAddress', {id: address.id} ).then(() => {
                    context.commit('setDefaultAddress',  address.id );
                    resolve( true ); 
                }).catch( error => {
                    reject(error) 
                });
            });
        },
        removeAddress(context, address_id) {
            return new Promise( (resolve, reject) =>{ 
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.rootGetters['auth/token'];
                axios.post('/customer/removeAddress', { 'id': address_id }).then( () => {
                    context.commit('removeAddress', address_id);
                    resolve( true );
                }).catch( error => {
                    reject(error) 
                });          
            });                
        },
        
    }
}
  

export default moduleCustomer;