<template>
    <div class="form-group sss row" :class="{error: error}">
        <label :for="name" class="col-form-label col-sm-3" >{{ label }}</label>
        <div class="position-relative col-sm-9 px-0">
            <input type="email"  :name="name" :value="value" v-on:input="updateValue($event.target.value)" 
                placeholder=" " class="form-control pr-4" v-on:blur="updateBlur" />
            <font-awesome-icon :icon="['fas', 'spinner']" class="inputValidationSpinner fa-spin ml-3" v-if="showSpinner" />
        </div>

        <div class="form-text text-danger col-sm-9 offset-sm-3" v-if="error">{{ error }}</div>
    </div>
</template>

<script>
export default {
    name: 'inputEmail',
    props: {
        name: {
            type: String,
            required: true,
        },
        value: {
            required: true,
        },
        label: {
            type: String,
            required: false,
            default: null
        },
        showSpinner: {
            type: Boolean,
            required: false,
            default: false
        },
        error: {
            type: String,
            default: null
        }
    },
    methods: {
        updateValue: function (value) {
            this.$emit('change', true);
            this.$emit('input', value);
        },
        updateBlur: function () {
            this.$emit('blur', true);
        }
    }
    
}
</script>