import axios from "axios";
axios.defaults.headers.common['Accept'] = 'application/json';

import {SCHEDULE_typeSubscription } from "@/constants/constants.js"; 

function isProductTemplateValid( scheduleType, productTemplate ) {
    
    if ( scheduleType == SCHEDULE_typeSubscription ) {
        productTemplate.isValid = ( productTemplate.amount.monday || productTemplate.amount.tuesday 
            || productTemplate.amount.wednesday || productTemplate.amount.thursday
            || productTemplate.amount.friday || productTemplate.amount.saturday 
            || productTemplate.amount.sunday ) > 0 ? true : false;
    } else {
        productTemplate.isValid =  productTemplate.date_amount == 0 ? false : true;
    }
    //console.log('isProductValid', productTemplate.name, productTemplate.isValid);
    return productTemplate.isValid;
}


const moduleOrder = {
    namespaced: true,
    state: {
        holidays: null,
        
        orderToken: localStorage.getItem('order_token') || null,
        schedule: {
            userId: null,
            scheduleType: null,
            address: null, 
            productTemplates: [],
            dateStart: null,
            dateEnd: null,
        },
        order: null,

        scheduleLoaded: false,
        orderLoaded: false,

        completedStepUser: false,
        completedStepScheduleType: false,
        completedStepAddress: false,
        completedStepProducts: false,
        completedStepDate: false,
        promotionCode : null
        
    },
    mutations: {
        setHolidays( state, holidays ) {  state.holidays = holidays;  },
        
        setOrderToken( state, orderToken ) {  state.orderToken = orderToken; state.orderLoaded = false; },
        setScheduleLoaded( state ) { state.scheduleLoaded = true; state.orderLoaded = false; },
        setUserId( state, userId ) {  
            state.userId = userId;
            state.completedStepUser = userId ? true : false;
            state.orderLoaded = false;
        },

        setScheduleType( state, scheduleType ) {  
            state.schedule.scheduleType = scheduleType;
            state.completedStepScheduleType = scheduleType ? true : false;
            state.orderLoaded = false;
        },

        setAddress( state, address ) {  
            state.schedule.address = address;
            state.completedStepAddress = address ? true : false;
            state.orderLoaded = false;
        },
        
        saveProductTemplate( state, productTemplate ) {
            const i = state.schedule.productTemplates.findIndex( item => item.product_id == productTemplate.product_id );
            if ( i > -1 ) {
                state.schedule.productTemplates[i] = productTemplate;
            } else {
                state.schedule.productTemplates.push( productTemplate );
            }
            state.orderLoaded = false;
        },

        removeProductTemplate( state, productTemplate ) {
            state.schedule.productTemplates = state.schedule.productTemplates.filter( item => item.product_id != productTemplate.product_id );
            state.orderLoaded = false;
        },

        setDate( state, dateStart, dateEnd = null ) {
            state.schedule.dateStart = dateStart;
            state.schedule.dateEnd = dateEnd ? dateEnd : dateStart;
            state.completedStepDate = dateStart ? true : false;
            state.orderLoaded = false;
        },

        setPromotionCode(state, promotionCode) {
            state.promotionCode = promotionCode;
            state.order.code = promotionCode;
        },

        proceedProductsCompleted( state ) {
            let completed = state.schedule.productTemplates.length > 0 ? true : false;
            if ( completed ) {
                for ( const productTemplate of state.schedule.productTemplates ) {
                    if ( !productTemplate.isValid ) {
                        completed = false;
                    }
                }
            }
            state.completedStepProducts = completed;
            state.orderLoaded = false;
        },

        setOrder( state, order ) { 
            state.order = order;
            state.orderLoaded = true;
            state.promotionCode = order.code;
        },

        reloadOrder( state ) { 
            state.scheduleLoaded = false;
            state.orderLoaded = false;
        },

        clearBasket( state ) {
            localStorage.setItem('order_token',null);
            state.orderToken = null,
            state.schedule = {
                userId: null,
                scheduleType: null,
                address: null, 
                productTemplates: [],
                dateStart: null,
                dateEnd: null,
            };
            state.order = null;
    
            state.scheduleLoaded = false;
            state.orderLoaded = false;
    
            state.completedStepUser = false;
            state.completedStepScheduleType = false;
            state.completedStepAddress = false;
            state.completedStepProducts = false;
            state.completedStepDate = false;
            state.promotionCode = false;
        }
    },
    getters: {
        schedule: state => state.schedule,
        order: state => state.order,
        holidays: state => state.holidays,
        productsAmount: state => state.schedule.productTemplates.length,
        
    },
    actions: {
        loadHolidays(context) {
            return new Promise( (resolve, reject) => {
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.rootGetters['auth/token'];
                axios.get('/customer/holidays' ).then(response => {
                    context.commit('setHolidays', response.data);
                    resolve( true );
                }).catch( error => {
                    reject(error) 
                });
            });
        },
        
        loadBasket(context) {
            return new Promise( (resolve, reject) => {
                if ( ! context.state.scheduleLoaded ) {
                    axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.rootGetters['auth/token'];
                    axios.get('/customer/order/prepareSchedule/' + context.state.orderToken ).then(response => {
                        
                        localStorage.setItem('order_token',response.data.orderToken);
                        context.commit('setOrderToken', response.data.orderToken);

                        context.commit('setScheduleType', response.data.scheduleType );
                        context.commit('setAddress', response.data.address);
                        context.commit('setUserId', response.data.userId );
                        context.commit('setDate', response.data.dateStart, response.data.dateEnd );

                        for ( const productTemplate of response.data.productTemplates) {
                            productTemplate.isValid = isProductTemplateValid( context.state.schedule.scheduleType, productTemplate );
                            context.commit('saveProductTemplate', productTemplate);
                        }
                        context.commit('proceedProductsCompleted');

                        context.commit('setScheduleLoaded');
                        resolve( true );
                    }).catch( error => {
                        reject(error) 
                    });
                } else {
                    resolve( true );
                }
            });
        },

        loadOrder( context ) {
            return new Promise( (resolve, reject) => {
                if ( ! context.state.orderLoaded ) {
                    axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.rootGetters['auth/token'];
                    axios.get('/customer/order/prepareOrder/' + context.state.orderToken ).then(response => {
                        context.commit('setOrder', response.data );
                        resolve( context.state.order );
                    }).catch( error => {
                        reject(error) 
                    });
                } else {
                    resolve( context.state.order );
                }
            });
        },

        reloadOrder( context ) {
            return new Promise((resolve, reject) => {
                if (context.state.orderLoaded) {
                    axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.rootGetters['auth/token'];
                    axios.get('/customer/order/prepareOrder/' + context.state.orderToken ).then(response => {
                        context.commit('reloadOrder');
                        context.commit('setOrder', response.data);
                    }).catch( error => {
                        reject(error) 
                    });
                }
            });
        },

        updateUserId( context, userId ) {
            return new Promise( (resolve, reject) => {
                if ( context.state.userId != userId) {
                    axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.rootGetters['auth/token'];
                    axios.post('/customer/order/user/' + context.state.orderToken, {} ).then( (response) => {
                        if ( response.data.orderToken != context.state.orderToken) {
                            context.commit('clearBasket');
                            localStorage.setItem('order_token',response.data.orderToken);
                            context.commit('setOrderToken', response.data.orderToken);
                            context.dispatch( 'loadOrder' ).then( () => {
                                resolve( true );
                            });
                        } else {
                            context.commit('setUserId', userId );
                            resolve( true );
                        }
                    }).catch( error => {
                        reject(error) 
                    });
                } else {
                    resolve( true );
                }
            });
        },

        updateScheduleType( context, scheduleType ) {
            return new Promise( (resolve, reject) => {
                context.commit('setScheduleType', scheduleType );
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.rootGetters['auth/token'];
                axios.post('/customer/order/schedule_type/' + context.state.orderToken, { scheduleType: scheduleType } ).then( () => {
                    resolve( true );
                }).catch( error => {
                    reject(error) 
                });
            });
        },

        updateAddress( context, address ) {
            return new Promise( (resolve, reject) => {
                context.commit('setAddress', address );
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.rootGetters['auth/token'];
                axios.post('/customer/order/address/' + context.state.orderToken, { addressId: address ? address.id : null } ).then( () => {
                    resolve( true );
                }).catch( error => {
                    reject(error) 
                });
            });
        },

        updateDates( context, dates ) {
            return new Promise( (resolve, reject) => {
                context.commit('setDate', dates.start, dates.end );
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.rootGetters['auth/token'];
                axios.post('/customer/order/dates/' + context.state.orderToken, { dateStart: dates.start, dateEnd: dates.end } ).then( () => {
                    resolve( true );
                }).catch( error => {
                    reject(error) 
                });
            });
        },

        updatePromotionCode( context, promotionCode) {
            return new Promise( (resolve, reject) => {
                context.commit('setPromotionCode', promotionCode);
                context.commit('reloadOrder');
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.rootGetters['auth/token'];
                axios.post('/customer/order/setCode/' + context.state.orderToken, { code: promotionCode }).then((response) => {
                    context.commit('setOrder', response.data);
                    resolve( context.state.order );
                    // resolve( true );
                }).catch( error => {
                    reject(error) 
                });
            });
        },

        updateProductTemplate( context, productTemplate ) {
            return new Promise( (resolve, reject) => {
                productTemplate.isValid = isProductTemplateValid( context.state.schedule.scheduleType, productTemplate );
                context.commit('saveProductTemplate', productTemplate );
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.rootGetters['auth/token'];
                axios.post('/customer/order/product/' + context.state.orderToken, { product: productTemplate } ).then( () => {
                    resolve( productTemplate );
                }).catch( error => {
                    reject(error) 
                });
            });
        },

        removeProductTemplate( context, productTemplate ) {
            return new Promise( (resolve, reject) => {
                context.commit('removeProductTemplate', productTemplate );
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.rootGetters['auth/token'];
                axios.post('/customer/order/removeProduct/' + context.state.orderToken, { product_id: productTemplate.product_id } ).then( () => {
                    resolve( productTemplate.product_id );
                }).catch( error => {
                    reject(error) 
                });
            });
        },

        setProductsCompleted( context ) {
            return new Promise( (resolve, reject) => {
                context.commit('proceedProductsCompleted' );
                if ( context.state.completedStepProducts )
                    resolve( true );
                else
                    reject() 
            });
        },

        setReady( context ){
            return new Promise( (resolve, reject) => {
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.rootGetters['auth/token'];
                axios.get('/customer/order/setReady/' + context.state.orderToken ).then( response => {
                    context.commit('clearBasket' );
                    resolve( response.data.orderId );
                }).catch( error => {
                    reject(error) 
                });
            });
        },
        
        getOrderListActual( context ){
            return new Promise( (resolve, reject) => {
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.rootGetters['auth/token'];
                axios.get('/customer/order/listActual' ).then( response => {
                    resolve( response.data );
                }).catch( error => {
                    reject(error) 
                });
            });
        },
        getOrderListAll( context ){
            return new Promise( (resolve, reject) => {
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.rootGetters['auth/token'];
                axios.get('/customer/order/listAll' ).then( response => {
                    resolve( response.data );
                }).catch( error => {
                    reject(error) 
                });
            });
        },
        getOrderInfo( context, id ){
            return new Promise( (resolve, reject) => {
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.rootGetters['auth/token'];
                axios.get('/customer/order/info/' + id ).then( response => {
                    resolve( response.data );
                }).catch( error => {
                    reject(error) 
                });
            });
        }
    }
}

export default moduleOrder;