import axios from "axios";
axios.defaults.headers.common['Accept'] = 'application/json';

const moduleDriver = {
    namespaced: true,
    state: {
        
    },
    mutations: {
        
    },
    getters: {
        
    },
    actions: {
        changeData(context, data, ) {
            return new Promise( (resolve, reject) =>{ 
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.rootGetters['auth/token'];
                axios.post('/driver/changeData', {
                    email: data.email,
                    name: data.name,
                    phone: data.phone,
                    surname: data.surname
                }).then(response => {
                    resolve( response.data.message );
                }).catch( error =>  {
                    reject( error.response.data.message) 
                });
            });
        },
        
    }
}
  

export default moduleDriver;