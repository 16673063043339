import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

// BootstrapVue
import { BootstrapVue } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
Vue.use(BootstrapVue)
//import { IconsPlugin } from 'bootstrap-vue'
//Vue.use(IconsPlugin)

import './scss/custom.scss'

//fontawesome
import { library } from '@fortawesome/fontawesome-svg-core'
import { faFontAwesome } from '@fortawesome/free-brands-svg-icons'
import { faFacebookF, faFacebookSquare } from '@fortawesome/free-brands-svg-icons'
import { faUser, faThLarge, faSpinner, faEye, faEyeSlash, faShoppingCart, faMapMarkerAlt,
    faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
library.add(faFacebookF, faUser, faThLarge, faCalendarAlt, faShoppingCart, faSpinner, faEye, faEyeSlash, 
    faFontAwesome, faMapMarkerAlt, faFacebookSquare, faChevronLeft, faChevronRight)
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.config.productionTip = false

//vuex
import Vuex from 'vuex'
Vue.use(Vuex)

//SimpleVueValidation
import SimpleVueValidation from 'simple-vue-validator';
import validationLanguage from './constants/validation_pl.js';
SimpleVueValidation.extendTemplates( validationLanguage );
Vue.use(SimpleVueValidation);

//facebook chat 
import VueFbCustomerChat from 'vue-fb-customer-chat';
Vue.use(VueFbCustomerChat, {
  page_id: 112278507340399,
  theme_color: '#ca8b3d',
  locale: 'pl_PL',
});

//facebook pixel
import VueFacebookPixel from 'vue-facebook-pixel';
Vue.use(VueFacebookPixel);
Vue.analytics.fbq.init('216619146525089');

//google analytics
import VueGtag from "vue-gtag";

Vue.use(VueGtag, {
  config: { id: "G-Q0RSJ05MD6" }
}, router);

//compoments
import ButtonLoader from '@/components/assets/ButtonLoader.vue';
import DismissAlert from '@/components/assets/DismissAlert.vue';
import InputText from '@/components/assets/form/InputText.vue';
import InputEmail from '@/components/assets/form/InputEmail.vue';
import InputPassword from '@/components/assets/form/InputPassword.vue';
import InputDatePicker from '@/components/assets/form/InputDatePicker.vue';
import InputTextarea from '@/components/assets/form/InputTextarea.vue';
import InputLabel from '@/components/assets/form/InputLabel.vue';
import InputSelect from '@/components/assets/form/InputSelect.vue';
import InputCheckbox from '@/components/assets/form/InputCheckbox.vue';
Vue.component('buttonLoader', ButtonLoader)
Vue.component('dismissAlert', DismissAlert)
Vue.component('inputText', InputText)
Vue.component('inputEmail', InputEmail)
Vue.component('inputPassword', InputPassword)
Vue.component('inputDatePicker', InputDatePicker)
Vue.component('inputTextarea', InputTextarea)
Vue.component('inputLabel', InputLabel)
Vue.component('inputSelect', InputSelect)
Vue.component('inputCheckbox', InputCheckbox)

//filters
import moment from 'moment'
Vue.filter('formatDate', function(value) {
    return (value && moment(String(value), 'YYYY-MM-DD', true).isValid() ) ?
        new Intl.DateTimeFormat('pl').format( moment( String(value)).toDate() ) : value;
    //     ? moment.utc(String(value), 'YYYY-MM-DD', true).format('DD/MM/YYYY') : value
});

Vue.filter('formatDateTime', function(value) {
    return (value && moment(String(value) ).isValid() ) ?
        new Intl.DateTimeFormat('pl', { dateStyle: 'short', timeStyle: 'short' }).format( moment( String(value)).toDate() )
        : value;
});


Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
